import styles from "./ChatSelectPage.module.css";
import { useStore } from "../store";
import config from "../config";

import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

export default function ChatSelectPage() {
    return (
        <div className={styles.page}>
            <div className={styles.logo}>{config.site.name}</div>
            {config.site.loginSubtitle ? (
                <div className={styles.subtitle}>
                    {config.site.loginSubtitle}
                </div>
            ) : null}
            <LoginForm />
        </div>
    );
}

const LoginForm = observer(() => {
    const store = useStore();
    const [username, setUsername] = useState(getDefaultUserIdFromHash());
    const [error, setError] = useState("");

    function handleSubmit(evt) {
        evt.preventDefault();
        if (username) {
            store
                .startChat(username.trim().toLowerCase())
                .then(({ error: newError }) => newError && setError(newError));
        }
    }

    const disabled = !username;

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <input
                type="text"
                className={styles.form__input}
                placeholder="Username"
                value={username}
                onChange={(evt) => setUsername(evt.target.value)}
            />
            <button
                type="submit"
                className={classnames(
                    styles.form__submit,
                    disabled ? styles.disabled : null
                )}
            >
                {"Start Chat"}
            </button>
            <div className={styles.form__error}>{error}</div>
        </form>
    );
});

function getDefaultUserIdFromHash() {
    const { hash } = window.location;
    return hash.length > 1 ? hash.replace("#", "") : "";
}
