import styles from "./ChatWebsite.module.css";
import ChatSelectPage from "./ChatSelectPage";
import LoginPage from "./LoginPage";
import { useStore } from "../store";
import config from "../config.json";

import {
    Chat,
    ChatSplitScreen,
    ChatMediaViewer,
} from "@faintlines/chat-client";
import "@faintlines/chat-client/dist/index.css";

import Widget from "@faintlines/widget";
import "@faintlines/widget/dist/index.css";

import { observer } from "mobx-react-lite";
import classnames from "classnames";
import React from "react";

function ChatWebsite() {
    const store = useStore();

    return (
        <div className={classnames(styles.app, config.site.theme)}>
            {config.login && !store.loggedIn ? <LoginPage /> : <ChatPage />}
            {config.widget ? (
                <Widget config={config.widget} storyConfig={config.story} />
            ) : null}
        </div>
    );
}

export default observer(ChatWebsite);

function ChatPage() {
    const store = useStore();

    return (
        <>
            <ChatMediaViewer store={store.chatStore} />
            {config.site.chatList ? (
                <SiteContentList />
            ) : (
                <SiteContentSingleChat />
            )}
        </>
    );
}

const SiteContentSingleChat = observer(() => {
    const store = useStore();
    const { activeChatId } = store;

    if (!activeChatId) {
        return <ChatSelectPage />;
    }
    return (
        <div className={styles.singleChatWrapper}>
            <Chat store={store.chatStore} showBackButton />
        </div>
    );
});

const SiteContentList = observer(() => {
    const store = useStore();
    return (
        <ChatSplitScreen store={store.chatStore} appTitle={config.site.name} />
    );
});
