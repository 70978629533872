import "./index.css";
import config from "./config";
import ChatWebsite from "./components/ChatWebsite";
import { Store, StoreContext } from "./store";

import * as Backend from "@faintlines/backend-client";
import { initSentry } from "@faintlines/monitoring";

import React from "react";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

initSentry();

Backend.configure({
    storyId: config.story.id,
    assetId: config.story.assetId,
});

if (process.env.NODE_ENV === "production" && config.backendUrl) {
    Backend.configure({
        baseUrl: config.backendUrl,
    });
}

const loginToken = new URL(window.location.href).searchParams.get("t");
const store = new Store({ loginToken });

ReactDOM.render(
    <React.StrictMode>
        <StoreContext.Provider value={store}>
            <HelmetProvider>
                <Helmet>
                    <title>{config.site.name}</title>
                    {config.site.description ? (
                        <meta
                            name="description"
                            content={config.site.description}
                        />
                    ) : null}
                    {config.site.favicon ? (
                        <link rel="icon" href={config.site.favicon} />
                    ) : null}
                </Helmet>
                <ChatWebsite />
            </HelmetProvider>
        </StoreContext.Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
