import styles from "./LoginPage.module.css";
import { useStore } from "../store";
import config from "../config";

import React from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";
import Loader from "react-feather/dist/icons/loader";

function LoginPage() {
    return (
        <div className={styles.page}>
            <div className={styles.logo}>{config.site.name}</div>
            {config.login.title ? (
                <div className={styles.subtitle}>{config.login.title}</div>
            ) : null}
            {config.login.type === "token" ? <TokenLoginForm /> : null}
        </div>
    );
}

export default observer(LoginPage);

const TokenLoginForm = observer(() => {
    const store = useStore();
    const { loginTokenValue, loginError, loggingIn } = store;

    function handleSubmit(evt) {
        evt.preventDefault();
        store.login();
    }

    const disabled = !loginTokenValue;

    return (
        <form
            className={classnames(
                styles.form,
                loggingIn ? styles.loading : null
            )}
            onSubmit={handleSubmit}
        >
            <input
                type="text"
                className={styles.form__input}
                placeholder={config.login.placeholder}
                value={loginTokenValue}
                onChange={(evt) => store.setLoginTokenValue(evt.target.value)}
            />
            <button
                type="submit"
                className={classnames(
                    styles.form__submit,
                    disabled ? styles.disabled : null
                )}
            >
                {loggingIn ? (
                    <Loader className={styles.form__submit__spinner} />
                ) : (
                    "Enter"
                )}
            </button>
            <div className={styles.form__error}>{loginError}</div>
        </form>
    );
});
